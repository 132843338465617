import { type MouseEvent } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import {
    ClickEventLabel,
    ClickEventType,
    GA4EventName,
    GA4FeatureCategory,
    trackAnalyticsEvent,
} from '@jsmdg/tracking';
import {
    Badge,
    BadgeColor,
    BadgeSize,
    Breakpoint,
    Button,
    ButtonColor,
    ButtonShape,
    ButtonSize,
    ButtonVariant,
    CheckIcon,
    RenderType,
    useBreakpoint,
    UserOutlineIcon,
} from '@jsmdg/yoshi';
import { trackCustomerAccount } from '../../helper/trackCutomerAccount';
import { useRedemptionContext } from '../../hooks/useRedemptionContext';
import { type HeaderContext } from '../../types';
import stylesCommon from '../common/common_styles.module.scss';
import styles from './CustomerAccountButton.module.scss';

const messages = defineMessages({
    newAccount: {
        defaultMessage: 'Benutzerkonto',
    },
    loggedInA11yText: {
        defaultMessage: 'Du bist eingeloggt',
    },
});

type CustomerAccountButtonProps = {
    readonly onClick: () => void;
    readonly onMouseLeave?: () => void;
    readonly onMouseEnter?: () => void;
    readonly layoutClassName?: string;
    readonly highlighted?: boolean;
};

const CustomerAccountButton = ({
    highlighted,
    layoutClassName,
    onClick,
    onMouseEnter,
    onMouseLeave,
}: CustomerAccountButtonProps): JSX.Element => {
    const intl = useIntl();
    const { redemptionInformation } = useRedemptionContext();
    const isNotSmallScreen = useBreakpoint(Breakpoint.XS);
    const isTabletScreen = useBreakpoint(Breakpoint.SM);
    const { isLoggedIn } = useFragmentContext<HeaderContext>();
    const buttonContent = (
        <>
            <span className="screenReaderOnly">{intl.formatMessage(messages.newAccount)}</span>
            <UserOutlineIcon
                className={classNames({
                    [styles.active]: highlighted,
                })}
            />
            {isLoggedIn && (
                <Badge
                    data-testid="account-badge"
                    className={classNames('position-absolute', stylesCommon.badge, {
                        'bottom-0': redemptionInformation?.isEnabled && !isTabletScreen,
                    })}
                    size={BadgeSize.Small}
                    color={BadgeColor.Complementary}
                    isRounded
                >
                    <span className="screenReaderOnly">
                        {intl.formatMessage(messages.loggedInA11yText)}
                    </span>
                    <CheckIcon />
                </Badge>
            )}

            {redemptionInformation?.isEnabled && (
                <Badge
                    data-testid="account-badge"
                    className={classNames('position-absolute d-sm-none', stylesCommon.badge)}
                    size={BadgeSize.Auto}
                    color={BadgeColor.Green}
                >
                    <span className="text-nowrap px-0-5x">
                        {intl.formatNumber(Number(redemptionInformation.vouchersTotal.amount), {
                            style: 'currency',
                            currency: redemptionInformation.vouchersTotal.currencyCode,
                        })}
                    </span>
                </Badge>
            )}
        </>
    );

    const handleCustomerAccountClick = async (event: MouseEvent): Promise<void> => {
        event.preventDefault();

        await trackCustomerAccount(
            ClickEventType.CLICK,
            ClickEventLabel.PROFILE,
            `${window.location.origin}/login`,
            `Customer Account ${isLoggedIn ? '' : 'not '}logged in`,
        );

        if (!isNotSmallScreen) {
            trackAnalyticsEvent({
                category: 'Header',
                action: 'click',
                nonInteraction: false,
                eventData: {
                    eventName: GA4EventName.ClickNavigationItem,
                    feature_category: GA4FeatureCategory.HeaderNavigation,
                    click_element: `Customer Account voucher uploaded & ${
                        !isLoggedIn ? 'not ' : ''
                    }logged in`,
                },
            });
        }

        onClick();
    };

    return (
        <>
            <Button
                onClick={handleCustomerAccountClick}
                data-testid="customer-account-button"
                className={classNames(layoutClassName, {
                    [styles.active]: isLoggedIn,
                })}
                as={RenderType.Button}
                onMouseLeave={onMouseLeave}
                onMouseEnter={onMouseEnter}
                variant={ButtonVariant.Ghost}
                size={ButtonSize.Small}
                color={ButtonColor.Dark}
                shape={ButtonShape.Icon}
                a11yText={intl.formatMessage(messages.newAccount)}
            >
                {buttonContent}
            </Button>

            {highlighted && (
                <>
                    <div
                        className={classNames(
                            'position-fixed w-100 h-100 top-0 bottom-0 start-0 end-0',
                            styles.overlay,
                        )}
                    />
                    <div
                        className={classNames(
                            'position-absolute w-100 h-100 top-0 bottom-0 start-0 end-0 d-flex justify-content-center align-items-center rounded-circle',
                            styles.buttonOverlay,
                        )}
                    >
                        {buttonContent}
                    </div>
                </>
            )}
        </>
    );
};

export { CustomerAccountButton };
